<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import List from './list'
import Form from './form'
import FormEditPermission from './form-edit-permission'

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List,
    Form,
    FormEditPermission,
  },
  data() {
    return {
      title: "Role",
      items: [
        {
          text: "VMCore"
        },
        {
          text: "Role",
          active: true
        }
      ]
    };
  },
  methods :{
    onActiveChange(b) {
      this.$refs.list.setActive(!b);
    },
    onRefresh(){
      this.$refs.list.fetchData()
    },
    onEdit(id){
      this.$refs.form.initUpdate(id)
    },
    onEditPermission(id){
      this.$refs.form_edit_permission.initUpdate(id)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <Form ref="form" @refresh="onRefresh" @active_change="onActiveChange"/>
        <FormEditPermission ref="form_edit_permission" @refresh="onRefresh" @active_change="onActiveChange"/>
        <List ref="list" @onEdit="onEdit"  @onEditPermission="onEditPermission"/>
      </div>
      
    </div>
  </Layout>
</template>