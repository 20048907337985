<script>
import { roleService } from "../../../helpers/backend/role.service"
import { permissionService } from "../../../helpers/backend/permission.service"
export default {
  data() {
    return {
      form: {
        id: null,
        name: "",
        permissions: [],
      },
      methods: ["GET", "POST", "PUT", "DELETE", "PATCH"],
      permissions: [],
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  computed: {
    isUpdateState() {
      return this.form.role_id != null
    },
    mappedPermissions() {
      return this.permissions.reduce((acc, item) => {
        if (!acc[item.module]) {
          acc[item.module] = {}
        }
        acc[item.module][item.method] = item
        return acc
      }, {})
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      // this.form.permissions = this.form.permissions.toString().split(",").map(Number)
      permissionService.updateRolePermissions(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error = "Failed to Update Permission's Role. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.role_id = null
      this.form.name = ""
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.name.$el.focus()
        this.busy = true
        roleService.getById(id).then(
          (data) => {
            if (data.role != null) {
              this.form.role_id = data.role.id
              this.form.name = data.role.name
              this.form.permissions = data.role.permissions.map(p => p.id)
            } else {
              this.showDismissibleAlert = true
              this.error = "Failed to get role. Error : role not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get role to update. Error : " + err
            this.busy = false
          }
        )
        permissionService.getAll().then(
          (data) => {
            if (data.permissions != null) {
              this.permissions = data.permissions
            } else {
              this.showDismissibleAlert = true
              this.error = "Failed to get permission. Error : role not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get permission to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    }
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-user"
                label="User Name:"
                label-for="input-user"
              >
                <b-form-input
                  id="input-user"
                  v-model="form.name"
                  type="text"
                  placeholder="User Name"
                  disabled
                  required
                  readonly
                  ref="name"
                ></b-form-input>
              </b-form-group>
              <div class="my-4 mx-2">
                <div class="row mb-2">
                  <div class="col"></div>
                  <div
                    v-for="method in methods"
                    :key="method"
                    class="col text-center"
                  >
                    {{ method }}
                  </div>
                </div>
                <div
                  v-for="key in Object.keys(mappedPermissions)"
                  :key="key"
                  class="row mb-2"
                >
                  <div class="col">{{ key }}</div>
                  <div
                    v-for="method in methods"
                    :key="method + key"
                    class="col text-center"
                  >
                    <b-form-checkbox
                      v-if="mappedPermissions[key][method]"
                      v-model="form.permissions"
                      :value="mappedPermissions[key][method].id"
                      name="permissions"
                    />
                  </div>
                </div>
              </div>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <!-- <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group> -->
  </div>
</template>



